import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .icon-license {
    :global(svg) {
      margin-right: 4px;
    }
    :global(svg path) {
      stroke: ${COLORS.black900};
    }
  }
  :global(.ekyc-alert) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default styles;
