import css from 'styled-jsx/css';

const styles = css`
  .campaign-dialog {
    max-width: 375px;
    margin: 0 auto;

    video::-webkit-media-controls {
      display: none;
    }

    video::-webkit-media-controls-play-button {
      display: none;
    }

    video::-webkit-media-controls-volume-slider {
      display: none;
    }

    video::-webkit-media-controls-mute-button {
      display: none;
    }

    video::-webkit-media-controls-timeline {
      display: none;
    }

    video::-webkit-media-controls-current-time-display {
      display: none;
    }

    .purchase-coin-pack-button {
      position: relative;
      padding: 24px 24px 0 24px;
    }
  }
`;

export default styles;
