import { useEffect, useState } from 'react';
import Image from 'next/image';
import Button from '@atoms/Button';
import Dialog from '@atoms/Dialog';
import { useAppSelector } from '@hooks/useAppSelector';
import { pushDataLayer } from '@utils/spa-ga4';
import styles from './css';

interface CampaignDialogProps {
  buttonLabel?: string;
  campaignCode: string;
  headerText?: string;
  imagePath?: string;
  localStorageKey: string;
  onPrimaryButtonClick?: () => void;
  shouldShowDialog: boolean;
  trackingEventName?: string;
  trackingType?: string;
}

export const CampaignDialog = ({
  buttonLabel = '詳細を確認する',
  campaignCode,
  headerText = '',
  imagePath = '',
  localStorageKey = '',
  onPrimaryButtonClick,
  shouldShowDialog = true,
}: CampaignDialogProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const { neighbor } = useAppSelector((state) => ({
    neighbor: state.neighbor,
  }));

  useEffect(() => {
    const isAlreadyShown = localStorage.getItem(localStorageKey) === 'false';

    if (shouldShowDialog && !isAlreadyShown) {
      setShowDialog(true);
    }
  }, [shouldShowDialog, localStorageKey]);

  const onClickClose = () => {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, 'false');
    }
    setShowDialog(false);
  };

  const handlePrimaryButtonClick = () => {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, 'false');
    }

    pushDataLayer({
      campaign_code: campaignCode,
      event: 'gtm-click',
      event_name: `popup_see_detail`,
      neighbor_id: neighbor?.id || '',
    });

    onPrimaryButtonClick?.();
    setShowDialog(false);
  };

  return (
    <Dialog
      headerText={headerText}
      show={showDialog}
      onClickButton={onClickClose}
    >
      <div className="campaign-dialog">
        <div className="dialog-image">
          <Image
            alt="campaign dialog"
            height={422}
            quality={100}
            src={imagePath}
            style={{
              height: 'auto',
              maxWidth: '100%',
            }}
            width={375}
          />
        </div>
        <div className="purchase-coin-pack-button">
          <Button
            label={buttonLabel}
            size="large"
            width="auto"
            onClick={handlePrimaryButtonClick}
          />
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </Dialog>
  );
};

export default CampaignDialog;
