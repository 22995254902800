import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Button from '@atoms/Button';
import Dialog from '@atoms/Dialog';
import { useActiveCoinPack } from '@hooks/useActiveCoinPack';
import { useCampaignOnClickPushDataLayer } from '@hooks/useCampaign';
import { useQueryParams } from '@hooks/useQueryParams';
import routes from '@utils/routes';
import styles from './css';

const SignUpCoinPackDialog = () => {
  const { t } = useTranslation('browse');
  const [showDialog, setShowDialog] = useState(false);
  const router = useRouter();

  const query = useQueryParams();

  const { isSignUpCoinPackageActive } = useActiveCoinPack();
  const campaignOnClickPushDataLayer = useCampaignOnClickPushDataLayer();
  const showSignUpCoinPackDialogLocalStorageKey =
    'showSignUpCoinPackDialog202410';

  const getShouldShowDialog = () => {
    if (!isSignUpCoinPackageActive) {
      return false;
    }

    return (
      localStorage.getItem(showSignUpCoinPackDialogLocalStorageKey) !==
        'false' && query.from !== 'complete'
    );
  };

  useEffect(() => {
    const shouldShowDialog = getShouldShowDialog();
    setShowDialog(shouldShowDialog);

    if (shouldShowDialog) {
      campaignOnClickPushDataLayer({
        event_name: 'popup',
        type: 'coinpack',
      });
    }
  }, [campaignOnClickPushDataLayer, query.from]);

  const onClickPrimaryButton = () => {
    localStorage.setItem(showSignUpCoinPackDialogLocalStorageKey, 'false');

    campaignOnClickPushDataLayer({
      event_name: 'popup_coinpack_see_detail',
      type: 'signup',
    });
    router.push(routes.coinHistory);
  };

  const onClickClose = () => {
    localStorage.setItem(showSignUpCoinPackDialogLocalStorageKey, 'false');
    setShowDialog(false);
  };

  return (
    <Dialog
      headerText="登録から30日限定！"
      show={showDialog}
      onClickButton={onClickClose}
    >
      <div className="campaign-dialog">
        <div className="dialog-image">
          <Image
            alt="おひとり様各種１回まで ウェルカムコインパック"
            height={422}
            quality={100}
            src="/images/campaign/coin-package/signup/dialog.png"
            style={{
              height: 'auto',
              maxWidth: '100%',
            }}
            width={375}
          />
        </div>
        <div className="purchase-coin-pack-button">
          <Button
            label={t('dialog.coinPack.buttonText')}
            size="large"
            width="auto"
            onClick={onClickPrimaryButton}
          />
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </Dialog>
  );
};

export default SignUpCoinPackDialog;
