import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import COLORS from '@utils/colors';
import styles from './css';

type AlertProps = {
  children: ReactNode;
  color?: 'blue' | keyof typeof COLORS;
  fontWeight?: 'bold' | 'normal';
  isRounded?: boolean;
  textAlign?: 'center' | 'left';
};

const Alert: FC<AlertProps> = ({
  children,
  color,
  fontWeight = 'bold',
  isRounded = true,
  textAlign = 'center',
}) => (
  <div
    className={`${classNames('alert-container', { '--rounded': isRounded })} ${
      color || ''
    } ${textAlign}`}
  >
    <div className={`alert-content ${fontWeight}`}>{children}</div>
    <style jsx={true}>{styles}</style>
  </div>
);

export default Alert;
