import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .alert-container {
    padding: 12px 16px;
    position: relative;
    z-index: 3;
    background: ${COLORS.lightGray};

    &.rounded {
      border-radius: 8px;
    }

    &.left {
      text-align: left;
    }

    &.center {
      text-align: center;
    }

    &.blue {
      background: #2b85d8;
      color: white;
    }

    &.purple100 {
      background: ${COLORS.purple100};
    }

    .alert-content {
      font-size: 12px;
      white-space: pre-wrap;
      &.normal {
        font-weight: normal;
      }
      &.bold {
        font-weight: bold;
      }

      :global(button) {
        text-decoration: underline;
        cursor: pointer;
      }

      :global(.alert-link) {
        text-decoration: underline;
        color: ${COLORS.white};
      }
    }
  }
`;

export default styles;
