import css from 'styled-jsx/css';

const styles = css`
  .campaign-dialog {
    max-width: 375px;
    margin: 0 auto;
    .purchase-coin-pack-button {
      position: relative;
      padding: 24px;
      padding-bottom: 0;
    }
  }
`;

export default styles;
