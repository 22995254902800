import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import IconLicense from '@assets/new/icon-license.svg';
import Alert from '@atoms/Alert';
import { TypoXS } from '@atoms/Typos';
import { useNeighbor } from '@hooks/useNeighbor';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import routes from '@utils/routes';
import styles from './css';

const EKycAlert = () => {
  const { t } = useTranslation('browse');

  const {
    isEKycComplete,
    isEKycPendingResult,
    isFreePlan,
    isJapaneseNationality,
    isKanjiNameRegistered,
    isLoggedIn,
  } = useNeighbor();
  const { isRegistrationCountryJapan } = useRegistrationCountry();

  return (
    (isLoggedIn &&
      isRegistrationCountryJapan &&
      !isFreePlan &&
      !isEKycPendingResult &&
      !isEKycComplete && (
        <>
          <Alert color="purple100" isRounded={false}>
            <Link
              className="ekyc-alert"
              href={
                isKanjiNameRegistered || !isJapaneseNationality
                  ? routes.profile
                  : routes.profileEdit
              }
            >
              <span className="icon-license">
                <IconLicense />
              </span>
              <TypoXS
                color="black900"
                text={
                  isKanjiNameRegistered || !isJapaneseNationality
                    ? t('eKyc.notYetEKyc')
                    : t('eKyc.notYetRegisteredKanjiName')
                }
                underline={true}
              />
            </Link>
          </Alert>
          <style jsx={true}>{styles}</style>
        </>
      )) ||
    null
  );
};

export default EKycAlert;
